<template lang="pug">
  .page-news
    section.news(v-if="article")
      .news__container
        h1.news__title.title(v-if="article.title") {{ article.title }}
        .news__img(v-if="article.image")
          img(
            :src="getImagePath(article.image)"
            :alt="article.title"
          )
        .news__text.simple__page(
          v-if="article.text__editor"
          v-html="article.text__editor"
        )
    section.latest-news
      .latest-news__container.latest-news__container--big
        .latest-news__inner
          NewsCarousel
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'NewsDetail',
  components: {
    NewsCarousel: () => import('@/components/NewsCarousel')
  },
  computed: {
    ...mapState({
      article: state => state.directus.article
    })
  },
  metaInfo () {
    const metaTags = [
      {
        vmid: 'description',
        name: 'description',
        content: this.article.meta_description || 'Спортивний клуб «Відар» проводить безкоштовні тренування для дітей від 6 до 18 років. Запрошуємо на занняття з Боксу, Дзюдо та бойового мистецтва Спас.'
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: this.article.og_title || 'Спортивний клуб «Vidar» | Тренування для молоді: Бокс, Спас, Дзюдо | Запоріжжя'
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: this.article.og_description || 'Спортивний клуб «Відар» проводить безкоштовні тренування для дітей від 6 до 18 років. Запрошуємо на занняття з Боксу, Дзюдо та бойового мистецтва Спас.'
      }
    ]
    // checking image
    if (this.article.og_image) {
      metaTags.push({
        vmid: 'og:image',
        property: 'og:image',
        content: `${ process.env.VUE_APP_DIRECTUS }/assets/${this.article.og_image}`
      })
    }
    // start meta tags
    return {
      title: this.article.meta_title,
      meta: metaTags
    }
  },
  watch: {
    '$route.params.slug': {
      handler: function(newSlug, oldSlug) {
        if (oldSlug && newSlug !== oldSlug) this.fetchArticle(this.$route.params.slug)
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.fetchArticle(this.$route.params.slug)
  },
  methods: {
    ...mapActions(['fetchArticle']),
    getImagePath (image) {
      return `${ process.env.VUE_APP_API }/assets/${image}`
    }
  }
}
</script>
